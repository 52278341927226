import React, { useState } from "react";
import {
  ModalBuilder,
  Form,
  Modal,
  getLookupData,
  Lookup,
} from "@redriver/cinnamon";
import { FloatingEditButton } from "components/buttons";
import {
  RegexInput,
  RemovableArrayRow,
  ProgrammeThemeDropdown,
  ProgrammeSubThemeDropdown,
  OrganisationTypeAhead,
} from "components/forms";
import produce from "immer";
import { useSelector } from "react-redux";
import {
  programmeSubThemesLookup,
  programmeThemeLookup,
} from "modules/lookups";
import { SocialMediaLinkType } from "constants/enums";
import { socialMediaTypesLookup } from "modules/lookups";
import { Popup } from "semantic-ui-react";
import {
  socialMediaLinkRegexMapping,
  socialMediaLinkExampleMapping,
  socialMediaLinkPrefillMapping,
} from "constants/regexes";
import { InfoIcon } from "components/icons";
import ShouldUpdateFutureEventProfilesMessage from "./ShouldUpdateFutureEventProfilesMessage";

const EditProfileDetails = ({
  profile,
  setProfile,
  onOpened,
  onClosed,
  submitAction,
  submitParams,
  children,
  extraInitialData = {},
  displayFutureProfileUpdateMessage,
  ...props
}) => {
  const [formData, setFormData] = useState(false);
  const initialData = {
    details: {
      displayName: profile?.displayName,
      jobTitle: profile?.jobTitle,
      themes: (profile?.themes || []).map((x) => x.value),
      subThemes: (profile?.subThemes || []).map((x) => x.value),
      subThemeNames: (profile?.subThemes || []).map((x) => x.text),
      twitter: profile?.twitter,
      linkedIn: profile?.linkedIn,
      otherSocialMedia: (profile?.otherSocialMedia || []).map((x) => ({
        ...x,
        type: x.socialMediaTypeId,
      })),
      organisationId: profile?.organisationId,
      organisationName: profile?.organisationName,
    },
    ...extraInitialData,
  };

  const availableThemes =
    useSelector((s) => getLookupData(s, programmeThemeLookup))?.response || [];
  const availableSubThemes =
    useSelector((s) => getLookupData(s, programmeSubThemesLookup))?.response ||
    [];

  return (
    <Lookup
      lookup={socialMediaTypesLookup}
      render={({ response }) => {
        const availableSocialMediaTypes = response || [];
        return (
          <ModalBuilder
            withForm
            initialData={initialData}
            renderTrigger={(openModal) => (
              <div className="edit-profile-details">
                <FloatingEditButton
                  onClick={() => {
                    openModal();
                    setFormData(initialData);
                    if (onOpened) onOpened();
                  }}
                  {...props}
                />
              </div>
            )}
            submitAction={submitAction}
            submitParams={submitParams}
            onSubmitted={() => {
              const nextProfile = produce(profile, (draft) => {
                draft.displayName = formData.details.displayName;
                draft.jobTitle = formData.details.jobTitle;
                draft.themes = formData.details.themes
                  .map((x) => availableThemes.find((t) => t.value == x))
                  .filter((x) => !!x);

                draft.subThemes = formData.details.subThemes
                  .map((x) => availableSubThemes.find((t) => t.value == x))
                  .filter((x) => !!x);
                let subThemeNamesToAdd = formData.details.subThemeNames.filter(
                  (st) => !draft.subThemes.map((s) => s.text).includes(st)
                );
                draft.subThemes.push(
                  ...subThemeNamesToAdd.map((st) => ({
                    text: st,
                    value: st,
                  }))
                );

                draft.twitter = formData.details.twitter;
                draft.linkedIn = formData.details.linkedIn;
                draft.otherSocialMedia = formData.details.otherSocialMedia.map(
                  (x) => ({
                    ...x,
                    socialMediaTypeId: x.type,
                    type: availableSocialMediaTypes.find(
                      (a) => a.value == x.type
                    )?.type,
                  })
                );
                draft.organisationId = formData.details.organisationId;
                draft.organisationName = formData.details.organisationName;
              });
              setProfile(nextProfile);
              if (onClosed) onClosed();
            }}
            onCancelled={() => {
              if (onClosed) onClosed();
            }}
            renderModal={(modalProps, formProps) => (
              <Modal.Edit
                {...modalProps}
                header="Edit Profile Details"
                onClick={(ev) => ev.stopPropagation()}
              >
                <Form
                  {...formProps}
                  onChange={(field, data, applyChanges) => {
                    formProps.onChange(field, data, applyChanges);
                    setFormData(applyChanges(formProps.value));
                  }}
                >
                  <Form.Object field="details">
                    <Form.Group widths="2">
                      <Form.Input
                        field="displayName"
                        label={
                          <label>
                            Profile Display Name
                            <Popup
                              inverted
                              content="This is the name that will be displayed to other participants at events that you attend, and is normally your first name and last name. Your full name is also editable on your Account details."
                              trigger={<InfoIcon />}
                            />
                          </label>
                        }
                        fluid
                        required
                        placeholder="Enter name to display on your profile"
                      />
                    </Form.Group>
                    <Form.Group widths="2">
                      <OrganisationTypeAhead
                        field="organisationId"
                        textField="organisationName"
                        label={
                          <label>
                            Organisation
                            <Popup
                              inverted
                              content={`Start typing to select your organisation from the list, or use the advanced search option (blue button). You can also create a new organisation by typing your organisation's name if it is not found. Please select "Independent" if you are not affiliated with a specific organisation.`}
                              trigger={<InfoIcon />}
                              wide
                            />
                          </label>
                        }
                        placeholder="Search or add organisation..."
                        fluid
                        required
                        searchSuggestions
                        allowAdditions
                        clearable
                        unknownValueText={
                          formProps.value.details.organisationId !=
                          formProps.value.details.organisationName
                            ? profile?.organisationName
                            : null
                        }
                      />
                      <Form.Input
                        field="jobTitle"
                        label="Position"
                        fluid
                        required
                        placeholder="Enter your position"
                      />
                    </Form.Group>
                    <ProgrammeThemeDropdown
                      field="themes"
                      label="Themes"
                      fluid
                      multiple
                      clearable
                      placeholder="Select one or more themes you are interested in"
                      showSelectionInfo
                    />
                    <ProgrammeSubThemeDropdown
                      field="subThemes"
                      textField="subThemeNames"
                      label="Interests"
                      fluid
                      multiple
                      clearable
                      search
                      allowAdditions
                      placeholder="Select one or more interests"
                      showSelectionInfo
                    />
                    <Form.Group widths="two">
                      <RegexInput
                        field="linkedIn"
                        label="LinkedIn"
                        fluid
                        regexPattern={
                          socialMediaLinkRegexMapping[
                            SocialMediaLinkType.LinkedIn
                          ]
                        }
                        regexFlags="i"
                        placeholder="Enter your LinkedIn profile link"
                        regexError={`Link must match the format ${
                          socialMediaLinkExampleMapping[
                            SocialMediaLinkType.LinkedIn
                          ]
                        }`}
                      />

                      <RegexInput
                        field="twitter"
                        label="Twitter"
                        fluid
                        regexPattern={
                          socialMediaLinkRegexMapping[
                            SocialMediaLinkType.Twitter
                          ]
                        }
                        regexFlags="i"
                        placeholder="Enter your Twitter profile link"
                        regexError={`Link must match the format ${
                          socialMediaLinkExampleMapping[
                            SocialMediaLinkType.Twitter
                          ]
                        }`}
                        focusPrefill={
                          socialMediaLinkPrefillMapping[
                            SocialMediaLinkType.Twitter
                          ]
                        }
                      />
                    </Form.Group>
                    <Form.Array
                      field="otherSocialMedia"
                      label="Other Social Media Links"
                      arrayKey="id"
                    >
                      {({ fields }) => (
                        <RemovableArrayRow columns={2}>
                          <Form.Dropdown
                            field="type"
                            optionField="typeOption"
                            lookup={socialMediaTypesLookup}
                            required
                            placeholder="Select social media type"
                            requiredError="Social media type is required"
                          />
                          <RegexInput
                            field="value"
                            fluid
                            required
                            regexPattern={
                              fields.typeOption
                                ? socialMediaLinkRegexMapping[
                                    fields.typeOption.type
                                  ]
                                : ".+"
                            }
                            regexFlags="i"
                            placeholder="Enter your profile link"
                            regexError={`Link must match the required format ${
                              fields.typeOption
                                ? socialMediaLinkExampleMapping[
                                    fields.typeOption.type
                                  ]
                                : ""
                            }`}
                            requiredError="Profile link is required"
                          />
                        </RemovableArrayRow>
                      )}
                    </Form.Array>
                    <Form.If
                      condition={({ fields }) =>
                        fields.otherSocialMedia.length == 0
                      }
                    >
                      <p>None</p>
                    </Form.If>
                    <Form.ArrayAdder
                      field="otherSocialMedia"
                      arrayKey="id"
                      icon="plus"
                      content="Add another social media link"
                      primary
                      compact
                    ></Form.ArrayAdder>
                  </Form.Object>
                  {displayFutureProfileUpdateMessage && (
                    <ShouldUpdateFutureEventProfilesMessage />
                  )}
                  {children}
                </Form>
              </Modal.Edit>
            )}
          />
        );
      }}
    />
  );
};

export default EditProfileDetails;
